<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{ $t(`integrations`) }}
                            <v-popover offset="10" :disabled="false" style="display:initial;">
                                <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover
                                           href="https://easy.jobs/docs/how-to-configure-google-meet-with-easy-jobs"
                                           target="_blank">
                                            {{ $t('How to configure google meet?') }}
                                        </a> <br/>
                                        <a href="https://easy.jobs/docs/how-to-configure-zoom-app-with-easy-jobs/"
                                           target="_blank">
                                            {{ $t('How to configure zoom?') }} </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="integration-card">
                                <div class="icon">
                                    <img src="/assets/images/gmeet.png" alt="">
                                </div>
                                <p>{{$t(`Configure easy.jobs with Google Meet to conduct remote interviews.`)}}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0)" class="button semi-button-info" @click="gotoUserApp('meet')">Configuration</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="integration-card">
                                <div class="icon">
                                    <img src="/assets/images/zoom.png" class="img-fluid" alt="">
                                </div>
                                <p>{{$t(`Configure easy.jobs with Zoom to conduct remote interviews.`)}}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0)" class="button semi-button-info" @click="gotoUserApp('zoom')">Configuration</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="integration-card">
                                <div class="icon">
                                    <img src="/assets/images/neuvoo.png" alt="">
                                </div>
                                <p>{{$t(`Enable this option to allow your job posts to be visible on Neuvco.`)}}</p>
                                <div class="d-flex justify-content-between align-items-center">

                                    <div class="selection-box" style="min-height:50px;">
                                        <input type="checkbox" class="notify"
                                               v-model="neuvoo.value"
                                               value="true"
                                               @change="toggleNeuvooSettings"
                                               id="neuvoo"
                                        />
                                        <label for="neuvoo" class="power-by__remove">
                                            <span v-if="neuvoo.value">{{ $t('On') }}</span>
                                            <span v-else>{{ $t('Off') }}</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="integration-card">
                                <div class="icon">
                                    <img src="/assets/images/google-jobs.png" alt="">
                                </div>
                                <p>{{$t(`Enable this option to allow your job posts to be visible on Google Jobs.`)}}</p>
                                <div class="d-flex justify-content-between align-items-center">

                                    <div class="selection-box" style="min-height:50px;">
                                        <input type="checkbox" class="notify"
                                               v-model="googleJobs.value"
                                               value="true"
                                               @change="toggleGoogleJobPost"
                                               id="googleJobPost"
                                        />
                                        <label for="googleJobPost" class="power-by__remove">
                                            <span v-if="googleJobs.value">{{ $t('On') }}</span>
                                            <span v-else>{{ $t('Off') }}</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <template>
                            <!--                        <div class="col-md-4 col-sm-6">-->
                            <!--                            <div class="integration-card">-->
                            <!--                                <div class="icon">-->
                            <!--                                    <img src="/assets/images/facebook.png" alt="">-->
                            <!--                                </div>-->
                            <!--                                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>-->
                            <!--                                <a href="#" class="button semi-button-info">Configuration</a>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <!--                        <div class="col-md-4 col-sm-6">-->
                            <!--                            <div class="integration-card">-->
                            <!--                                <div class="icon">-->
                            <!--                                    <img src="/assets/images/linkedin.png" alt="">-->
                            <!--                                </div>-->
                            <!--                                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>-->
                            <!--                                <a href="#" class="button semi-button-info">Configuration</a>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <!--                        <div class="col-md-4 col-sm-6">-->
                            <!--                            <div class="integration-card">-->
                            <!--                                <div class="icon">-->
                            <!--                                    <img src="/assets/images/indeed.png" alt="">-->
                            <!--                                </div>-->
                            <!--                                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>-->
                            <!--                                <a href="#" class="button semi-button-info">Configuration</a>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <!--                        <div class="col-md-4 col-sm-6">-->
                            <!--                            <div class="integration-card">-->
                            <!--                                <div class="icon">-->
                            <!--                                    <img src="/assets/images/glassdoor.png" alt="">-->
                            <!--                                </div>-->
                            <!--                                <p>Lorem Ipsum is simply dummy text of the printing and industry.</p>-->
                            <!--                                <a href="#" class="button semi-button-info">Configuration</a>-->
                            <!--                            </div>-->
                            <!--                        </div>-->

                        </template>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import {mapState} from "vuex";
import client from "../../../app/api/Client";
import {INTEGRATIONS} from '../../../extra/constants'

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },
    data() {
        return {
            googleJobs: {
                key: INTEGRATIONS.GOOGLE_JOB_POST,
                value: false
            },
            neuvoo: {
                key: INTEGRATIONS.NEUVOO_JOB_POST,
                value: false
            },
        }

    },
    computed: {
        ...mapState(['company']),
    },
    methods: {
        canSetupRemoteInterview() {
            if (!this.company.package_rule.remote_interview) {
                this.$toast.error(this.$t(`Remote interview not supported. Please upgrade subscription package.`));
                this.$router.push({
                    name: 'my-account'
                });
            }
        },
        toggleNeuvooSettings() {
            this.updateIntegrations(this.neuvoo);
        },
        toggleGoogleJobPost() {
            this.updateIntegrations(this.googleJobs);
        },
        async updateIntegrations(data) {
            try {
                let {data: {message}} = await client().post(`/company/setting/integrations`, data);
                this.$toast.success(this.$t(message));
            } catch (e) {
            }
        },
        async getIntegrations() {
            try {
                let {data} = await client().get(`/company/setting/integrations`);
                this.googleJobs.value = data.data.google_job_post;
                this.neuvoo.value = data.data.neuvoo_job_post;
            } catch (e) {
            }
        },
        gotoUserApp(type) {
            this.$router.push({name: 'company.setting.user-app', params: {type: type}})
        }
    },
    created() {
        // this.canSetupRemoteInterview();
        this.getIntegrations();
    },
    mounted() {
    }
}
</script>
<style scoped>
.copy-url {
    cursor: pointer;
}

.w-15 {
    width: 15% !important;
}

input.notify[type=checkbox] {
    display: none;
}

input.notify + label {
    cursor: pointer;
    width: 64px;
    height: 30px;
    background: #FF5F74;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify:checked + label {
    background: #60CE83;
}

input.notify:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

input.notify + label > span {
    color: #fff;
    text-transform: uppercase;
    float: right;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
}

input.notify:checked + label > span {
    float: left;
}

</style>
